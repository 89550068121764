import { ImageList, ImageListItem } from '@mui/material'
import { useState } from 'react'
import { ReviewImageGalleryModal } from './ReviewImageGalleryModal'

type ImageProps = {
  thumb_url: string
  original_url: string
}

type ReviewItemImageListProps = {
  images_data: ImageProps[]
  className: string
}

export function ReviewItemImageList(props: ReviewItemImageListProps) {
  const { className, images_data } = props
  const [isDisplayImageGallery, setIsDisplayImageGallery] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const handleClickImage = (index: number) => {
    setImageIndex(index)
    setIsDisplayImageGallery(true)
  }

  return (
    <>
      <ImageList variant='quilted' cols={3} rowHeight={50} className={`${className} justify-end`}>
        {images_data.map((item, index) => (
          <ImageListItem
            key={item.thumb_url}
            className='h-[50px] w-[50px] cursor-zoom-in object-cover'
          >
            <img
              src={`${item.thumb_url}`}
              loading='lazy'
              onClick={() => handleClickImage(index)}
              width={50}
              height={50}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ReviewImageGalleryModal
        images={images_data}
        isDisplayed={isDisplayImageGallery}
        setIsDisplayed={setIsDisplayImageGallery}
        imageIndex={imageIndex}
      />
    </>
  )
}
